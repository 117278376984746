import React, { Component } from 'react';
import { Route } from 'react-router';
import * as Colyseus from "colyseus.js";
import Lottie from 'react-lottie';
import nosleep from 'nosleep.js';
import "animate.css";
import LoggingService from "services/logging";
import * as Sentry from "@sentry/react";

import Loading from "components/Loading";
import DefaultView from "components/DefaultView";

import styles from 'components/ClientStyles.module.scss';
import BlackIconSelected from 'images/Colours/Black_Selected.png';
import BlackIconUnselected from 'images/Colours/Black_Unselected.png';
import WhiteIconSelected from 'images/Colours/White_Selected.png';
import WhiteIconUnselected from 'images/Colours/White_Unselected.png';
import BlueIcon from 'images/Colours/Blue.png';
import BrownIcon from 'images/Colours/Brown.png';
import GreenIcon from 'images/Colours/Green.png';
import GreyIcon from 'images/Colours/Grey.png';
import OrangeIcon from 'images/Colours/Orange.png';
import PinkIcon from 'images/Colours/Pink.png';
import PurpleIcon from 'images/Colours/Purple.png';
import RedIcon from 'images/Colours/Red.png';
import YellowIcon from 'images/Colours/Yellow.png';
import ColourBg from 'images/colour-bg.png';
import CaptureIcon from 'images/ColourCaptor.png';

import getAvatarById from "constants/avatars";

var noSleep = new nosleep();
var supportsVibrate = "vibrate" in navigator;

const GameStates = {
    // your game states here (identical to server)
    Loading: "loading",
    Tutorial: "tutorial",
    Idle: "idle",
    Question: "question",
    ColourCapture: "colour_capture",
    Answers: "answers",
    GameOver: "game_over",
    EndGame: "end_game",
};

const gameId = "colour_brain";

export class Client extends Component {
    static displayName = Client.name;

    constructor(props) {
        super(props);

        this.client = new Colyseus.Client(process.env.REACT_APP_GAME_SERVER_URL);
        this.state = {
            roomId: 0,
            room: null,
            //room: {
            //    state: {
            //        players: {
            //            1: {
            //                id: 1,
            //                avatar: 2,
            //                name: "SCOTT",
            //                colourData: {
            //                    score: 5,
            //                }
            //            },
            //            2: {
            //                id: 2,
            //                avatar: 2,
            //                name: "SCOTT",
            //                colourData: {
            //                    score: 5,
            //                }
            //            },
            //            3: {
            //                id: 3,
            //                avatar: 2,
            //                name: "SCOTT",
            //                colourData: {
            //                    score: 5,
            //                }
            //            },
            //        }
            //    }
            //},
            myId: null,
            roomState: null,
            redirect: null,
            redirectURL: "",
            isPaused: false,
            reconnectionToken: "",
            gameState: GameStates.Loading,
            contentFilter: 1,
            logStreamId: "",

            player: null,
            //player: {
            //    id: 1,
            //    avatar: 2,
            //    name: "SCOTT",
            //},
            showDefaultView: true,
            doingTutorial: false,
            showQuestion: false,
            pallete: [],
            question: "",
            requiredColours: 1,
            canSubmit: false,
            canCapture: false,
            showCaptureSection: false,
            //showCaptureSection: true,
            currentCaptureId: "",
            captureIds: [],
            showCaptured: false,
            captorName: "",
            capturedCardCount: 0,
            showGameOver: false,
            isLeader: false,

            gotLocationPing: true,
            connectionIssue: false,
            hostConnected: false,
            players: [],
        };
        this.locationCheckInterval = null;
    }

    componentDidMount() {
        this.setTags();

        setTimeout(() => {
            this.doReconnect();
        }, 1500);

        document.addEventListener('click', function enableNoSleep() {
            document.removeEventListener('click', enableNoSleep, false);
            noSleep.enable();
        }, false);
    }

    componentDidUpdate() {
        if ([GameStates.Loading, GameStates.EndGame].includes(this.state.gameState) == false && this.state.redirectURL.length == 0) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = undefined;
        }
    }

    setTags() {
        const token = this.getQueryStringValue('token');
        Sentry.setTag('isPlayer', true);

        if (token) {
            const [roomId, reconnectToken] = token.split(':');
            Sentry.setTag('roomId', roomId);
            Sentry.setTag('reconnectToken', reconnectToken);
        }
    }

    getQueryStringValue(key) {
        return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
    }

    // update this with your gamestates, if player joins/reconnects mid-game
    doCatchup(state) {
        const player = state.players[this.state.myId];
        switch (state.colourData.gameState) {
            case GameStates.Tutorial:
                if (!player.votedSkip) {
                    this.setState({ doingTutorial: true, showDefaultView: false, });
                }
                break;
            case GameStates.Question:
                if (!player.colourData.ready) {
                    if (!this.state.showQuestion) {
                        this.state.room.send("cb_request_question");
                    }
                }
                break;
            case GameStates.GameOver:
                this.setState({ showGameOver: true, });
                break;
            default:
                this.setState({
                    showGameOver: false,
                    showQuestion: false,
                    showDefaultView: true,
                });
                break;
        }
    }

    // checking to ensure player is in the right place e.g. in the correct game
    startLocationChecks() {
        this.state.room.send("location_check", { gameId, });
        this.locationCheckInterval = setInterval(() => {
            if (this.state.gotLocationPing) {
                this.setState({ gotLocationPing: false, connectionIssue: false, });
            } else {
                this.setState({ connectionIssue: true, });
            }
            this.state.room.send("location_check", { gameId, });
        }, 10000);
    }

    skipTutorial() {
        this.state.room.send("vote_skip");
        this.setState({ doingTutorial: false, showDefaultView: true, });
    }

    goToLobby() {
        this.setState({ redirectURL: `${this.getRedirectURL()}/?token=${this.state.reconnectionToken}` });
        this.state.room.leave(false);
        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
    }

    selectColour(colour) {
        if (colour.isActive) {
            this.state.room.send("cb_select_colour", { colour });
        }
    }

    submitAnswers = () => {
        if (this.state.canSubmit) {
            this.state.room.send("cb_submit_answers");
            this.setState({ showDefaultView: true, showQuestion: false, });
        }
    }

    toggleShowCaptureList(value) {
        if (value == true) {
            if (this.state.canCapture == false) value = false;
        }
        this.setState({ showCaptureSection: value, });
    }

    selectCapture(id) {
        this.setState({ currentCaptureId: id, });
    }

    doCapture = () => {
        if (this.state.canCapture && this.state.captureIds.includes(this.state.currentCaptureId)) {
            this.state.room.send("cb_do_capture", { captureId: this.state.currentCaptureId, });
            this.setState({ showCaptureSection: false, currentCaptureId: "", });
        }
    }

    showCaptured(captorName, capturedCardCount) {
        this.setState({ captorName, showCaptured: true, capturedCardCount, });
        setTimeout(() => {
            this.setState({ showCaptured: false, });
        }, 7500);
    }

    getColourByName(colourName, selected = false) {
        colourName = colourName.toLowerCase();
        switch (colourName) {
            case "black":
                return selected ? BlackIconSelected : BlackIconUnselected;
            case "white":
                return selected ? WhiteIconSelected : WhiteIconUnselected;
            case "blue":
                return BlueIcon;
            case "brown":
                return BrownIcon;
            case "green":
                return GreenIcon;
            case "grey":
                return GreyIcon;
            case "orange":
                return OrangeIcon;
            case "pink":
                return PinkIcon;
            case "purple":
                return PurpleIcon;
            case "red":
                return RedIcon;
            case "yellow":
                return YellowIcon;
        }
    }

    getCaptureList = () => {
        let list = this.state.captureIds.map((x) => {
            return this.state.room.state.players[x];
        });
        list.sort((a, b) => {
            return b.colourData.score - a.colourData.score;
        });

        return list.map((x, index) => {
            if (x.id != this.state.myId) {
                return <div onClick={() => this.selectCapture(x.id)} className={`${styles.captureSelection} ${this.state.currentCaptureId == x.id && styles.selected}`}>
                    <div className={`${styles.potato}`}>
                        <Lottie
                            options={getAvatarById(x.avatar).idleAnim}
                            width="100%"
                            height="100%"
                            isClickToPauseDisabled={true}
                        />
                    </div>
                    <div className={styles.text}>{x.name}</div>
                    <div className={`${styles.text} ${index == 0 && styles.scoreTop}`}>{x.colourData.score}pts</div>
                </div>
            }
        })
    }

    getRedirectURL(display = false) {
        let url = display ? process.env.REACT_APP_GAME_CITY_URL_DISPLAY : process.env.REACT_APP_GAME_CITY_URL;
        if (this.state.room) {
            if (this.state.room.name != "game_city_room") {
                url = display ? process.env.REACT_APP_HOME_URL_DISPLAY : process.env.REACT_APP_HOME_URL;
            }
        }
        return url;
    }
    updateToken(token) {
        var url = new URL(window.location.href);

        try {
            window.history.replaceState(null, null, (url.pathname) + (`?token=${token}`));
        } catch (e) {
            console.warn(e)
        }
    }

    checkAndAddPlayer(player) {
        if (!this.state.players.find(elem => elem.id === player.id)) {
            this.setState((prevState) => {
                return { players: [...prevState.players, player] }
            });
        }
    }

    doReconnect = () => {
        // fetch room and session id from query params
        const token = this.getQueryStringValue("token");

        // start reconnecting player to game
        this.client.reconnect(token).then(room => {
            console.log(room.sessionId, "joined", room.name);
            this.setState({ room: room, roomId: room.id, myId: room.sessionId, reconnectionToken: room.reconnectionToken, });
            this.updateToken(room.reconnectionToken);
            room.send("update_player_token", { reconnectionToken: room.reconnectionToken });
            
            room.onStateChange.once((state) => {
                console.log("this is the first room state!", state);
                const player = state.players[room.sessionId];
                if (!player) window.location = this.getRedirectURL();
                Sentry.setUser({ id: player.uniqueId });
                LoggingService.streamLog(state.uniqueId, `Player ${this.state.myId} ${player.name} Reconnected to Colour Brain, Reconnection Token: ${room.reconnectionToken}`);

                this.startLocationChecks();
                if (state.colourData) {
                    const captureIds = state.colourData.captureIds;
                    const canSubmit = player.colourData.answerCount == state.colourData.requiredColours;
                    const canCapture = !state.colourData.capturePlayed && player.colourData.hasCapture && !player.colourData.isLeader && captureIds.length > 0;
                    const isLeader = player.colourData.isLeader;
                    this.setState({
                        roomState: state,
                        player,
                        isPaused: state.isPaused,
                        pallete: player.colourData.palette,
                        captureIds,
                        canSubmit,
                        canCapture,
                        isLeader,
                        logStreamId: state.uniqueId,
                    });
                    this.doCatchup(state);
                }

                room.state.colourData.listen("gameState", (currentValue, previousValue) => {
                    console.log(`gameState change detected : ${currentValue}`);
                    this.setState({ gameState: currentValue });
                });
            });
            room.onStateChange((state) => {
                console.log("room has new state:", state);
                if (state.colourData) {
                    const captureIds = state.colourData.captureIds;
                    const canSubmit = state.players[room.sessionId].colourData.answerCount == state.colourData.requiredColours;
                    const canCapture = !state.colourData.capturePlayed && state.players[room.sessionId].colourData.hasCapture && !state.players[room.sessionId].colourData.isLeader && captureIds.length > 0;
                    const isLeader = state.players[room.sessionId].colourData.isLeader;
                    this.setState({
                        roomState: state,
                        player: state.players[room.sessionId],
                        isPaused: state.isPaused,
                        pallete: state.players[room.sessionId].colourData.palette,
                        canSubmit,
                        canCapture,
                        captureIds,
                        isLeader,
                    });
                    this.doCatchup(state);
                }
            });

            room.state.players.onAdd((player, key) => {
                this.checkAndAddPlayer(player);

                player.listen("connected", (currentValue, previousValue) => {
                    let statePlayers = [...this.state.players];
                    let index = statePlayers.findIndex(elem => elem.id === player.id);
                    statePlayers[index].connected = currentValue;
                    this.setState({ players: statePlayers });
                });
            });

            room.state.colourData.listen("contentFilter", (currentValue) => {
                this.setState({ contentFilter: currentValue })
            });

            room.state.host.listen("connected", (value) => {
                this.setState({ hostConnected: value });
            });

            room.onMessage("show_tutorial", (message) => {
                console.log("show_tutorial", "received on", room.name, message);
                this.setState({ doingTutorial: true, showDefaultView: false, });
            });
            room.onMessage("end_tutorial", (message) => {
                console.log("end_tutorial", "received on", room.name, message);
                this.setState({ doingTutorial: false, showDefaultView: true, });
            });
            room.onMessage("toggle_pause", (message) => {
                console.log("toggle_pause", "received on", room.name, message);
                this.setState({ isPaused: message.pause });
            });
            room.onMessage("location_confirmed", (message) => {
                console.log("location_confirmed", "received on", room.name, message);
                this.setState({ gotLocationPing: true, });
            });

            // your game message handlers here
            room.onMessage("show_question", (message) => {
                console.log("show_question", "received on", room.name, message);
                this.setState({ showDefaultView: false, showQuestion: true, question: message.question, requiredColours: message.requiredColours, });
            });
            room.onMessage("show_captured", (message) => {
                console.log("show_captured", "received on", room.name, message);
                if (message.capturedPlayer.id == this.state.myId) {
                    this.showCaptured(message.captorPlayer.name, message.cardCount);
                }
            });
            room.onMessage("force_submit", (message) => {
                this.setState({ showDefaultView: true, showQuestion: false, showCaptureSection: false, });
            });
            room.onMessage("game_over", (message) => {
                console.log("game_over", "received on", room.name, message);
                this.setState({ showGameOver: true, });
            });
            room.onMessage("new_game", (message) => {
                console.log("new_game", "received on", room.name, message);
                this.setState({ showGameOver: false, });
            });


            room.onMessage("game_starting", (message) => {
                console.log("game_starting", "received on", room.name, message);
                if (message.gameId != gameId) {
                    this.goToLobby();
                }
            });
            room.onMessage("catchup", (message) => {
                console.log("catchup", "received on", room.name, message);
                if (message.data.id == this.state.myId) {
                    this.doCatchup(message.gameState, message.data);
                }
            });
            room.onMessage("host_joined_lobby", (message) => {
                console.log("host_joined_lobby", "received on", room.name, message);
                this.goToLobby();
            });
            room.onMessage("change_game", (message) => {
                console.log("change_game", "received on", room.name, message);
                this.goToLobby();
            });

            room.onError((code, message) => {
                console.log(this.client.id, "couldn't join", room.name);
                LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} OnError at Colour Brain, code: ${code} Message: ${JSON.stringify(message)}`);
                //LoggingService.logError(message, code);
            });
            room.onLeave((code) => {
                console.log(this.client.id, "left", room.name);
                LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} Left Colour Brain, Code: ${code}`);

                if (!this.state.redirectURL) {
                    if (code == 4050) {
                        this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
                        if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
                    } else {
                        this.doReconnect();
                    }
                } else {
                    setTimeout(() => {
                        this.setState({ redirect: true, });
                    }, 1500);
                }
            });
        }).catch(e => {
            console.log("JOIN ERROR", e);
            this.setState({ redirect: true, redirectURL: `${this.getRedirectURL()}/` });
            const message = e.message ? e.message : "An error occured joining Colour Brain.";

            if (this.state.logStreamId.length > 0) LoggingService.streamLog(this.state.logStreamId, `Player ${this.state.myId} OnJoinError at Colour Brain: ${JSON.stringify(e)}`);

            //LoggingService.logError(message, e);
            if (this.locationCheckInterval) clearInterval(this.locationCheckInterval);
        });

    }

    render() {
        const selectedMaxColours = this.state.pallete.filter(x => x.selected == true).length == this.state.requiredColours;

        if (this.state.redirectURL) {
            return (
                <React.Fragment>
                    <div id="clientContainer" className={styles.clientContainer}>
                        <Loading loadingText={"Sending you to the lobby!"} />
                    </div>

                    <div style={{ opacity: 0 }}>
                        {
                            this.state.redirect ?
                                <Route path="/" render={() => (window.location = this.state.redirectURL)} />
                                :
                                null
                        }
                    </div>
                </React.Fragment>
            )
        }
        return (
            <div>
                {
                    this.state.room ?
                        <div id="clientContainer" className={`${styles.clientContainer} ${this.state.contentFilter === 0 ? styles.kids : this.state.contentFilter === 1 ? styles.family : styles.adult}`} >
                            {
                                this.state.connectionIssue &&
                                <div className={styles.connectionIssueContainer}>
                                    <div className={styles.connectionText}>There might be an issue with your connection...<br />Click below to refresh!</div>
                                    <div className={styles.refreshButton} onClick={() => window.location.reload()}>&#x21bb;</div>
                                </div>
                            }
                            {
                                this.state.showDefaultView &&
                                <DefaultView room={this.state.room} player={this.state.player} isLeader={this.state.isLeader} showGameOver={this.state.showGameOver} hostConnected={this.state.hostConnected} players={this.state.players} contentFilter={this.state.contentFilter} />
                            }
                            {
                                this.state.isPaused &&
                                <div className={styles.pauseContainer}>
                                    <div className={styles.pauseText}>Paused</div>
                                </div>
                            }
                            {
                                this.state.doingTutorial &&
                                <div className={styles.skipContainer} onClick={() => this.skipTutorial()}>
                                    <div className={styles.skipButton}>Skip Tutorial</div>
                                </div>
                            }
                            {
                                this.state.showQuestion &&
                                <div className={styles.questionSection}>
                                    <div className={styles.questionBox}>
                                        <div className={styles.questionText}>{this.state.question}</div>
                                        <div className={styles.requiredColoursText}>select {this.state.requiredColours} colours</div>
                                    </div>
                                    <div className={styles.coloursSection}>
                                        <div className={styles.coloursBox}>
                                            {
                                                this.state.pallete.map((x) => {
                                                    return <div className={`${styles.colour} ${x.isActive ? x.selected ? styles.selected : selectedMaxColours ? styles.disabled : "" : styles.disabled}`} onClick={() => this.selectColour(x)}>
                                                        <img className={`${styles.colourBg}`} src={ColourBg} />
                                                        <img className={styles.colourImg} src={this.getColourByName(x.colourName, x.selected)} />
                                                        <div className={`${styles.colourName}`}>{x.colourName.toLowerCase()}</div>
                                                    </div>
                                                })
                                            }
                                            <div className={`${styles.colour} ${this.state.canCapture ? "" : styles.disabled}`} onClick={() => this.toggleShowCaptureList(true)}>
                                                <img className={`${styles.colourBg} ${styles.show}`} src={ColourBg} />
                                                <img className={styles.colourImg} src={CaptureIcon} />
                                                <div className={`${styles.colourName} ${styles.white}`}>capture</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                this.state.showCaptured &&
                                <div className={styles.capturedSection}>
                                    <img className={styles.captureIcon} src={CaptureIcon} />
                                    <div className={styles.capturedText}><span style={{ color: "#FF485E" }}>{this.state.captorName}</span> has stolen <span style={{ color: "#FF485E"}}>{this.state.capturedCardCount}</span> of your colours!</div>
                                </div>
                            }
                            {
                                this.state.showCaptureSection &&
                                <div className={styles.captureListSection}>
                                    <div className={styles.closeButton} onClick={() => this.toggleShowCaptureList(false)}>X</div>
                                    <img src={CaptureIcon} className={styles.captureIcon} />
                                    <div className={styles.captureTitle}>Who's colour's will you capture?</div>
                                    <div className={styles.captureList}>
                                        {
                                            this.getCaptureList()
                                        }
                                    </div>
                                    <div className={styles.buttonsSection}>
                                        <div className={styles.captureButton} onClick={this.doCapture}>CAPTURE</div>
                                        {/*<div className={styles.captureButton} onClick={() => this.toggleShowCaptureList(false)}>Cancel</div>*/}
                                    </div>
                                </div>
                            }
                        </div>
                        :
                        <Loading loadingText={"Connecting you to the game..."} noBg={true} hideLoader={false} />
                }
            </div>
        );
    }
}
